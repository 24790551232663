<template>
  <div class="abcd">
    <img src="../../content/images/favicon.svg" id="logo" alt="smartyfile" />

    <h1>Session Expired</h1>

    <p>Your session has expired due to inactivity.</p>

    <p class="d-flex align-baseline">
      <span>Please</span>
      <b-button class="session-expired-login m-0 p-0 ml-1 mr-1" variant="link" v-on:click="goBack">login</b-button>
      <span>again</span>
    </p>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SessionExpired",
  methods: {
    ...mapActions("auth", ["expireSession"]),
    goBack() {
      this.$router.back();
    },
  },
  mounted() {
    this.expireSession();
  },
};
</script>

<style lang="scss" scoped>
#logo {
  width: 300px;
  animation: 5s infinite ease-in-out alternate b-icon-animation-fade;
}
.abcd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.session-expired-login {
  border-style: none;
  outline: none;
  font-weight: bold;
}
</style>
